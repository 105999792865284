import { useEffect, useState } from "react";
import { Typography } from '@mui/material';

const Redirect = () => {
  const [ isWeb, setIsWeb ] = useState(false);
  useEffect(() => {
    let AppRedirect = window.AppRedirect;
    var qs = AppRedirect.queryString;


    /**
     * BASE CONFIG
     */
    let schema = "uhoo"
    let packageAndroid = "com.brilliant.brilliantapp"
    let appStore = "https://apps.apple.com/us/app/uhoo/id1202020080"
    let playStore = "https://play.google.com/store/apps/details?id=com.brilliant.brilliantapp&hl=es&gl=US"

    const finishLoad = () => setIsWeb(true);

    if(!qs['path']) {
      return finishLoad()
    }
    if( qs['path'].startsWith('app/') ) {
      qs['path'] = qs['path'].substring(4);
    }

    /**
     * Override base
     */
    if(qs['schema']) {
      schema = qs['schema'];
    }
    if(qs['android']) {
      packageAndroid = qs['android'];
    }
    if(qs['ios']) {
      appStore = `https://apps.apple.com/us/app/${qs['ios']}`;
    }
    if(qs['playStore']) {
      playStore = `https://play.google.com/store/apps/details?id=${qs['android']}&hl=es&gl=US`;
    }

    /**
     * Init deep link
     */
    AppRedirect.redirect({
      iosApp: `${schema}://${qs['path']}`,
      iosAppStore: appStore,
      android: {
        'host': qs['path'],
        'scheme': schema,
        'package': packageAndroid,
        'fallback': playStore
      }
    }, finishLoad);

  }, []);

  if(!isWeb) {
    return null
  }
  return (
    <Typography variant="body1" color="rgb(24, 62, 85)" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
      Lo sentimos! este contenido no está disponible para web, Para visualizarlo, abre el link desde un dispositivo móvil
    </Typography>
  )
}

export default Redirect;
