import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/landing';
import { VantiPreguntas } from './pages/vanti-preguntas/vantiPreguntas';
import Redirect from './pages/redirect';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/vanti-preguntas' element={<VantiPreguntas />} />
                <Route path='/redirect' element={<Redirect />} />
                <Route path='/' element={<Landing />} />
            </Routes>
        </Router>
    );
}

export default App;
