import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useWidth } from '../../utils/useWidth';

export const Faqs = () => {
    const { t, i18n } = useTranslation();
    const width = useWidth();

    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            pt={15}
        >
            <Grid item xl={12} lg={12} xs={12}>
                <Typography
                    variant={width === 'xs' ? 'h5' : 'h3'}
                    color='primary'
                    fontWeight='bold'
                >
                    {t('landing.faqs.title')}
                </Typography>
            </Grid>
            <Grid item xl={10} lg={10} xs={11} mt={6}>
                <Accordion
                    sx={{
                        boxShadow: '0px 16px 40px rgba(112, 144, 176, 0.1)',
                        borderRadius: 2,
                    }}
                >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <Typography
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            color='primary'
                            py={1}
                            px={3}
                        >
                            {t('landing.faqs.faq1.question')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('landing.faqs.faq1.answer')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        mt: 3,
                        boxShadow: '0px 16px 40px rgba(112, 144, 176, 0.1)',
                        borderRadius: 2,
                    }}
                >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <Typography
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            color='primary'
                            py={1}
                            px={3}
                        >
                            {t('landing.faqs.faq2.question')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('landing.faqs.faq2.answer')}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        mt: 3,
                        boxShadow: '0px 16px 40px rgba(112, 144, 176, 0.1)',
                        borderRadius: 2,
                    }}
                >
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <Typography
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            color='primary'
                            py={1}
                            px={3}
                        >
                            {t('landing.faqs.faq3.question')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t('landing.faqs.faq3.answer')}</Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
};
