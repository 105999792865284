import { createSvgIcon } from '@mui/material/utils';

export const UhooMarca = createSvgIcon(
  <svg
    // width='92'
    // height='34'
    viewBox='0 0 92 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
        d='M39.4877 28.9079C37.8292 28.9079 37 28.0591 37 26.3617V7.54621C37 5.84874 37.8292 5 39.4877 5C41.1244 5 41.9428 5.84874 41.9428 7.54621V14.5896C42.5101 13.7519 43.2412 13.1236 44.1359 12.7048C45.0306 12.2639 46.0235 12.0434 47.1146 12.0434C50.8899 12.0434 52.7775 14.314 52.7775 18.8553V26.3617C52.7775 28.0591 51.9483 28.9079 50.2898 28.9079C49.5042 28.9079 48.8932 28.6874 48.4567 28.2465C48.0421 27.8056 47.8348 27.1773 47.8348 26.3617V19.0868C47.8348 17.9625 47.6275 17.1579 47.2128 16.6729C46.7982 16.1658 46.1872 15.9123 45.3798 15.9123C44.3323 15.9123 43.4921 16.243 42.8593 16.9043C42.2483 17.5657 41.9428 18.4475 41.9428 19.5497V26.3617C41.9428 28.0591 41.1244 28.9079 39.4877 28.9079Z'
        fill='#FF8614'
    />
    <path
        d='M63.5379 28.974C61.8139 28.974 60.3082 28.6323 59.0207 27.9489C57.755 27.2435 56.773 26.2625 56.0747 25.0059C55.3764 23.7273 55.0272 22.2172 55.0272 20.4756C55.0272 18.7561 55.3764 17.2681 56.0747 16.0115C56.773 14.7329 57.755 13.7519 59.0207 13.0685C60.3082 12.3851 61.8139 12.0434 63.5379 12.0434C65.2619 12.0434 66.7567 12.3851 68.0224 13.0685C69.3099 13.7519 70.3028 14.7329 71.0011 16.0115C71.7213 17.2681 72.0813 18.7561 72.0813 20.4756C72.0813 22.2172 71.7213 23.7273 71.0011 25.0059C70.3028 26.2625 69.3099 27.2435 68.0224 27.9489C66.7567 28.6323 65.2619 28.974 63.5379 28.974ZM63.5379 25.2374C64.5854 25.2374 65.4364 24.8516 66.0911 24.08C66.7676 23.3084 67.1059 22.107 67.1059 20.4756C67.1059 18.8664 66.7676 17.6869 66.0911 16.9374C65.4364 16.1658 64.5854 15.78 63.5379 15.78C62.4904 15.78 61.6394 16.1658 60.9847 16.9374C60.33 17.6869 60.0027 18.8664 60.0027 20.4756C60.0027 22.107 60.33 23.3084 60.9847 24.08C61.6394 24.8516 62.4904 25.2374 63.5379 25.2374Z'
        fill='#FF8614'
    />
    <path
        d='M82.8463 29C80.9992 29 79.3859 28.6339 78.0064 27.9017C76.6503 27.1459 75.5982 26.0948 74.85 24.7484C74.1018 23.3785 73.7277 21.7606 73.7277 19.8946C73.7277 18.0523 74.1018 16.4579 74.85 15.1116C75.5982 13.7417 76.6503 12.6906 78.0064 11.9584C79.3859 11.2262 80.9992 10.8601 82.8463 10.8601C84.6934 10.8601 86.295 11.2262 87.6511 11.9584C89.0306 12.6906 90.0944 13.7417 90.8426 15.1116C91.6142 16.4579 92 18.0523 92 19.8946C92 21.7606 91.6142 23.3785 90.8426 24.7484C90.0944 26.0948 89.0306 27.1459 87.6511 27.9017C86.295 28.6339 84.6934 29 82.8463 29ZM82.8463 24.9965C83.9686 24.9965 84.8805 24.5831 85.5819 23.7564C86.3067 22.9297 86.6691 21.6425 86.6691 19.8946C86.6691 18.1704 86.3067 16.9067 85.5819 16.1036C84.8805 15.2769 83.9686 14.8636 82.8463 14.8636C81.724 14.8636 80.8122 15.2769 80.1107 16.1036C79.4093 16.9067 79.0586 18.1704 79.0586 19.8946C79.0586 21.6425 79.4093 22.9297 80.1107 23.7564C80.8122 24.5831 81.724 24.9965 82.8463 24.9965Z'
        fill='#FF8614'
    />
    <path
        d='M16.9888 0C7.60026 0 0 7.60526 0 17C0 21.9434 2.1236 26.4171 5.49901 29.5263C5.7449 29.75 5.90138 30.0855 5.90138 30.4434C5.90138 30.779 5.76726 31.0697 5.56607 31.2934L3.75542 32.8592C3.57659 32.9711 3.46483 33.1724 3.46483 33.3737C3.46483 33.7092 3.75542 34 4.09073 34C4.11308 34 4.15779 34 4.18014 34H17.0112C26.3997 34 34 26.3947 34 17C34 7.60526 26.3774 0 16.9888 0Z'
        fill='#FF8614'
    />
    <path
        d='M17.0571 28C14.1238 28 11.8762 27.2469 10.3143 25.7407C8.77143 24.2345 8 21.9848 8 18.9917V9.69379C8 8.82483 8.22857 8.15862 8.68571 7.69517C9.14286 7.23172 9.79048 7 10.6286 7C11.4667 7 12.1143 7.23172 12.5714 7.69517C13.0286 8.15862 13.2571 8.82483 13.2571 9.69379V19.0497C13.2571 20.5366 13.581 21.6566 14.2286 22.4097C14.8762 23.1628 15.819 23.5393 17.0571 23.5393C18.2762 23.5393 19.2095 23.1628 19.8571 22.4097C20.5048 21.6566 20.8286 20.5366 20.8286 19.0497V9.69379C20.8286 8.82483 21.0476 8.15862 21.4857 7.69517C21.9429 7.23172 22.5905 7 23.4286 7C24.2667 7 24.9048 7.23172 25.3429 7.69517C25.781 8.15862 26 8.82483 26 9.69379V18.9917C26 21.9848 25.2381 24.2345 23.7143 25.7407C22.2095 27.2469 19.9905 28 17.0571 28Z'
        fill='white'
    />
  </svg>,
  'UhooMarca'
);
