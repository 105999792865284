import { createSvgIcon } from '@mui/material/utils';

export const Icon1 = createSvgIcon(
  <svg
    // width='60'
    // height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
        d='M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z'
        fill='white'
    />
    <path
        d='M34.5276 18.8887H38.2898C39.8478 18.8887 41.1104 20.1619 41.1104 21.7331V25.527C41.1104 27.0982 39.8478 28.3714 38.2898 28.3714H34.5276C32.9695 28.3714 31.707 27.0982 31.707 25.527V21.7331C31.707 20.1619 32.9695 18.8887 34.5276 18.8887Z'
        fill='#FF8614'
    />
    <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M21.7092 18.8887H25.4714C27.0296 18.8887 28.292 20.1619 28.292 21.7331V25.527C28.292 27.0982 27.0296 28.3714 25.4714 28.3714H21.7092C20.1512 28.3714 18.8887 27.0982 18.8887 25.527V21.7331C18.8887 20.1619 20.1512 18.8887 21.7092 18.8887ZM21.7092 31.6281H25.4714C27.0296 31.6281 28.292 32.9013 28.292 34.4726V38.2664C28.292 39.8367 27.0296 41.1109 25.4714 41.1109H21.7092C20.1512 41.1109 18.8887 39.8367 18.8887 38.2664V34.4726C18.8887 32.9013 20.1512 31.6281 21.7092 31.6281ZM38.2903 31.6281H34.5281C32.97 31.6281 31.7076 32.9013 31.7076 34.4726V38.2664C31.7076 39.8367 32.97 41.1109 34.5281 41.1109H38.2903C39.8483 41.1109 41.1109 39.8367 41.1109 38.2664V34.4726C41.1109 32.9013 39.8483 31.6281 38.2903 31.6281Z'
        fill='#183E55'
    />
  </svg>,
  'Icon1'
);
