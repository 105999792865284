import { Box, Modal, Stack, ThemeProvider } from '@mui/material';
import { useRef, useState } from 'react';
import { CompanyInfo } from '../components/main-page/company-info';
import { Faqs } from '../components/main-page/faqs';
import { Features } from '../components/main-page/features';
import { Goals } from '../components/main-page/goals';
import { Header } from '../components/main-page/header';
import { Join } from '../components/main-page/join';
import { Main } from '../components/main-page/main';
import { Plans } from '../components/main-page/plans';
import { Footer } from '../components/main-page/footer';
import { theme } from '../theme/base-theme-options';
import '../App.css';
import { I18nextProvider } from 'react-i18next';
import { useWidth } from '../utils/useWidth';
import i18n from '../components/main-page/localization/languaje';
import SocialNetworks from '../components/main-page/social-networks';

function Landing() {
    const companyRef = useRef<null | HTMLDivElement>(null);
    const featuresRef = useRef<null | HTMLDivElement>(null);
    const plansRef = useRef<null | HTMLDivElement>(null);
    const resourcesRef = useRef<null | HTMLDivElement>(null);
    const customersRef = useRef<null | HTMLDivElement>(null);

    const executeScrollToCompany = () => {
        if (companyRef.current)
            companyRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const executeScrollToFeatures = () => {
        if (featuresRef.current)
            featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const executeScrollToPlans = () => {
        if (plansRef.current)
            plansRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const executeScrollToResources = () => {
        if (resourcesRef.current)
            resourcesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const executeScrollToCustomers = () => {
        if (customersRef.current)
            customersRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const width = useWidth();

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <Stack>
                    <Header
                        onClickCompany={executeScrollToCompany}
                        onClickFeatures={executeScrollToFeatures}
                        onClickPlans={executeScrollToPlans}
                        onClickResources={executeScrollToResources}
                        onClickCustomers={executeScrollToCustomers}
                        openFormModal={handleOpen}
                    />
                    <Main openFormModal={handleOpen} />
                    <div ref={companyRef}>
                        <CompanyInfo openFormModal={handleOpen} />
                        <Goals openFormModal={handleOpen} />
                    </div>
                    <div ref={featuresRef}>
                        <Features openFormModal={handleOpen} />
                    </div>
                    {/* <div ref={plansRef}>
                        <Plans openFormModal={handleOpen} />
                    </div> */}
                    <div ref={resourcesRef}>
                        <Join openFormModal={handleOpen} />
                    </div>
                    <div ref={customersRef}>
                        <Faqs />
                    </div>
                    <Box display='flex' justifyContent='center' pt={10}>
                        <SocialNetworks styles={{ width: '80%' }} />
                    </Box>

                    <Footer />
                </Stack>
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: width === 'xs' ? 340 : 500,
                            height: 700,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            borderRadius: 2,
                            p: 2,
                        }}
                    >
                        <iframe
                            src='https://t-ven14964.activehosted.com/f/4'
                            width={width === 'xs' ? 340 : 500}
                            height={700}
                        />
                    </Box>
                </Modal>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default Landing;
