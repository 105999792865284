import { Button, Grid, Stack, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { IComponentsProps } from '../../utils/types.d';
import { useWidth } from '../../utils/useWidth';

const Chip = styled(Box)<{ width: number; height: number }>(
    ({ width, height }) => ({
        // width: 178,
        // height: 48,
        width,
        height,
        borderRadius: '6px',
        border: '1px solid #EAEEEF',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        boxShadow: '0px 16px 50px rgba(112, 144, 176, 0.12)',
    })
);

export const Features = (props: IComponentsProps) => {
    const width = useWidth();
    const { t, i18n } = useTranslation();

    return (
        <Grid
            container
            display={'flex'}
            justifyContent={'center'}
            pt={width === 'xs' ? 0 : 22}
        >
            <Grid item xl={3} md={4} display={'flex'} alignItems={'center'}>
                <img
                    src={require('../../assets/imgs/platform.jfif')}
                    alt={'item.title'}
                    loading='lazy'
                    width={width === 'xs' ? 250 : 473}
                    height={width === 'xs' ? 200 : 244}
                    style={{
                        objectFit: 'cover',
                        display: width === 'xs' ? 'none' : 'block',
                    }}
                />
            </Grid>
            <Grid
                item
                xl={4.5}
                md={6}
                ml={width === 'xl' || width === 'lg' ? 10 : 0}
                xs={10}
                direction={'column'}
                display={'flex'}
                justifyContent={'center'}
            >
                <Typography
                    color='primary'
                    variant={width === 'xs' ? 'h5' : 'h3'}
                    fontWeight='bold'
                >
                    {t('landing.features.title')}
                </Typography>
                <Box ml={-2}>
                    <ul>
                        <li>
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.numeral1')}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.numeral2')}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.numeral3')}
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.numeral4')}
                            </Typography>
                        </li>
                    </ul>
                </Box>
                <Grid item container pt={3}>
                    <Grid item lg={4} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip1')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip2')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={width === 'xs' ? 3 : 0} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip3')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={3} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip4')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={3} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip5')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={3} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip6')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={3} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip7')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={3} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.features.chip8')}
                            </Typography>
                        </Chip>
                    </Grid>
                    <Grid item lg={4} pt={3} xs={6}>
                        <Chip
                            py={1}
                            px={2}
                            width={width === 'xs' ? 115 : 178}
                            height={48}
                        >
                            <Typography
                                color='primary'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                                sx={{ fontWeight: 600 }}
                            >
                                {t('landing.features.chip9')}
                            </Typography>
                        </Chip>
                    </Grid>
                </Grid>
                <Button
                    variant='contained'
                    color='secondary'
                    sx={{ mt: 5, width: 222, height: 40 }}
                    onClick={props.openFormModal}
                >
                    <Typography color='white'>
                        {t('landing.features.button')}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
};
