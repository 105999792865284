import React from "react";
import "./vantiPreguntas.css";

export const VantiPreguntas = () =>  {
  return (
    <div>
      <div className="navegation">
        <img src="https://brilliantmedia.blob.core.windows.net/asset-7ecbfea0-28c2-4fd8-8c20-4a39ec800e6d/Logo-Vanti-Bco-Header.png?sv=2017-04-17&sr=c&si=8948eb83-2f78-45b5-be73-7757e3c611a8&sig=Z4MztgeaWQGnMjm8vAYsoLATuRPgRuxPMQ9HLJXfDfE%3D&st=2020-04-20T17%3A49%3A14Z&se=2120-04-20T17%3A49%3A14Z" class="logo-navigation" alt="cell phones" />
      </div>
      <table width="100%"  border="0" cellpadding="0" cellspacing="0" align="center" style= {{margin: "0px auto"}}>
        <tbody>
          <tr>
            <td width="100%" align="middle" style={{padding: "0px"}}>
              <img src="https://brilliantmedia.blob.core.windows.net/asset-dac0b2eb-148a-40f8-a069-24ce23d76c93/Somos-Vanti-Nombre-App.png?sv=2017-04-17&sr=c&si=f4bcac5f-e3a3-4201-9b65-b7b159e098a4&sig=z3QEonNCCnzuElisrfRRmydKjmjm84qH1V4WmNUlOfA%3D&st=2020-04-20T17%3A43%3A52Z&se=2120-04-20T17%3A43%3A52Z" class="logo-somos-vanti" alt="cell phones" />
            </td>
          </tr>
          <tr>
            <td width="100%" align="middle" style={{padding: "0px"}}>
              <div className="container-message">
                <span className="headin-message"> ¿Dudas? </span>
                <div className="content-message">
                  <span className="d-block"> Aquí encontrarás las respuestas que estás </span>
                  <span className="d-block"> buscando </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="accordion" id="frequent-questions">
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-1">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                      <span className="text-left  question-tittle"> ¿Para qué sirve la aplicación Somos Vanti? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-1" className="collapse" aria-labelledby="heading-1" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Es una plataforma que centraliza la experiencia colaborativa con tu equipo de trabajo. Podrás mantenerte al tanto de las novedades y la información importante de tu organización, crear publicaciones, compartir contenido y contactar a tus compañeros. Es una red social corporativa que te brinda una nueva experiencia laboral.
                    </div>
                  </div>
                </div>
                
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-2">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                      <span className="text-left  question-tittle"> ¿Cómo inicio sesión? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-2" className="collapse" aria-labelledby="heading-2" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      <span className="d-block"> Ingresa tu e-mail corporativo. Enseguida, recibirás un PIN numérico como respuesta a este mismo correo; ingrésalo y estarás listo para empezar a usar tu aplicación. </span>
                      <span className="d-block">* Únicamente tendrás que hacer esto la primera vez que ingreses o al cerrar sesión e ingresar nuevamente. </span>
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-3">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                      <span className="text-left  question-tittle"> ¿Cómo obtengo una cuenta? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-3" className="collapse" aria-labelledby="heading-3" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Al ser una plataforma corporativa, no es permitido que una sola persona cree su cuenta. Tu organización es la encargada de activar a los usuarios dentro de la aplicación.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-4">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                      <span className="text-left  question-tittle"> ¿Qué es el PIN? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-4" className="collapse" aria-labelledby="heading-4" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Queremos que tu ingreso sea fácil, rápido y seguro. Por eso, usamos una serie de números que se envía a tu e-mail registrado para validar tu ingreso por primera vez a la aplicación o al cerrar la aplicación e ingresar nuevamente.
                    </div>
                  </div>
                </div>
                
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-5">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                      <span className="text-left  question-tittle"> ¿Qué son las audiencias? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-5" className="collapse" aria-labelledby="heading-5" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Las audiencias son grupos que segmentan personas o tipos de contenido.
                    </div>
                  </div>
                </div>
                
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-6">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                      <span className="text-left  question-tittle"> ¿Por qué no veo el contenido de ciertas audiencias? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-6" className="collapse" aria-labelledby="heading-6" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Únicamente puedes ver el contenido de las audiencias a las que perteneces. Para ser añadido a una nueva audiencia, puedes ponerte en contacto con el administrador de tu comunidad.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-7">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                      <span className="text-left  question-tittle"> ¿Puedo crear una audiencia? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-7" className="collapse" aria-labelledby="heading-7" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Solo los administradores de la comunidad pueden crear audiencias, si tienes un perfil de usuario no podrás realizar esta acción.
                    </div>
                  </div>
                </div>
                
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-8">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                      <span className="text-left  question-tittle"> ¿Quién me asigna mi perfil? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-8" className="collapse" aria-labelledby="heading-8" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Por defecto, tienes un perfil de usuario con el cual podrás visualizar contenidos e interactuar con el aplicativo y sus audiencias. Los perfiles diferentes son asignados por el administrador de tu comunidad.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-9">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4" type="button" data-toggle="collapse" data-target="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                      <span className="text-left  question-tittle"> ¿Qué funcionalidades tiene mi aplicación? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-9" className="collapse" aria-labelledby="heading-9" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Tiene un muro, en el que están todas las publicaciones; un directorio, en el que encuentras los datos de contacto de todos tus compañeros registrados; y una sección en la que puedes gestionar tus datos de perfil.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-10">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-10" aria-expanded="false" aria-controls="collapse-10">
                      <span className="text-left  question-tittle"> ¿Para qué sirve el directorio? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-10" className="collapse" aria-labelledby="heading-10" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Desde allí, puedes contactar vía telefónica o e-mail a cualquiera de tus compañeros registrados o miembros de la organización Vanti.
                    </div>
                  </div>
                </div>
                
                
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-11">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-11" aria-expanded="false" aria-controls="collapse-11">
                      <span className="text-left  question-tittle"> ¿Qué datos puedo editar en mi perfil? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-11" className="collapse" aria-labelledby="heading-11" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Nombre, segundo nombre, apellido, cumpleaños, teléfono y género.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-12">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-12" aria-expanded="false" aria-controls="collapse-12">
                      <span className="text-left  question-tittle"> ¿Por qué no puedo modificar el correo electrónico en mi perfil? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-12" className="collapse" aria-labelledby="heading-12" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Tu correo electrónico es el dato con el que tu organización te registra. Si deseas modificarlo contacta al administrador de tu comunidad.
                      
                    </div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-13">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-13" aria-expanded="false" aria-controls="collapse-13">
                      <span className="text-left  question-tittle"> ¿Cómo publico un nuevo post? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-13" className="collapse" aria-labelledby="heading-13" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Una vez estés dentro de la aplicación, elige la opción (+). En el panel de creación, elige la categoría a la que pertenece tu post (General, Evento, Anuncio, Beneficio o Urgente). Asigna un título, redacta el texto de tu contenido, elige un archivo multimedia de tu celular o crea uno nuevo desde tu dispositivo. Selecciona la(s) audiencia(s) a la que quieres dirigir tu post y confirma la publicación.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-14">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-14" aria-expanded="false" aria-controls="collapse-14">
                      <span className="text-left  question-tittle"> ¿Qué tipo de contenido puedo subir en un post? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-14" className="collapse" aria-labelledby="heading-14" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Puedes publicar cualquier tipo de archivo multimedia. La app te da la opción de tomar una nueva foto, grabar un video, subir un archivo desde tu galería de imagen o desde tu galería de video.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-15">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-15" aria-expanded="false" aria-controls="collapse-15">
                      <span className="text-left  question-tittle"> ¿Por qué el contenido que quiero publicar no sube a la aplicación? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-15" className="collapse" aria-labelledby="heading-15" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      Revisa que tu contenido multimedia (imágenes, videos, gif) no superen 2 MB para que suba adecuadamente a la plataforma. Los contenidos que superen este peso, puede que no carguen, se visualicen de forma errónea o generen que la plataforma se ponga lenta.
                    </div>
                  </div>
                </div>
                
                
                <div className="card">
                  <div className="card-header m-0 p-0" id="heading-16">
                    <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-16" aria-expanded="false" aria-controls="collapse-16">
                      <span className="text-left  question-tittle"> ¿Por qué no puedo elegir más de una categoría por post? </span>
                      <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                    </button>
                  </div>
                  <div id="collapse-16" className="collapse" aria-labelledby="heading-16" data-parent="#frequent-questions">
                    <div className="card-body answer-question">
                      A cada post se le asigna una categoría para facilitar su búsqueda dentro del muro de publicaciones. Tener más de una categoría podría crear confusión en la segmentación de tu contenido. Te sugerimos que combines la categoría con el título o el texto del post, para hacer énfasis en la intención de tu post.
                    </div>
                  </div>
                </div>
                
                  
                  <div className="card">
                    <div className="card-header m-0 p-0" id="heading-17">
                      <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-17" aria-expanded="false" aria-controls="collapse-17">
                        <span className="text-left  question-tittle"> ¿Cómo encuentro una publicación que me interesó días atrás? </span>
                        <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                      </button>
                    </div>
                    <div id="collapse-17" className="collapse" aria-labelledby="heading-17" data-parent="#frequent-questions">
                      <div className="card-body answer-question">
                        En la parte superior de tu muro, tienes la opción de filtrar las publicaciones para encontrarlas por categorías. También puedes usar la lupa para encontrar post utilizando palabras clave.
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  <div className="card">
                    <div className="card-header m-0 p-0" id="heading-18">
                      <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-18" aria-expanded="false" aria-controls="collapse-18">
                        <span className="text-left  question-tittle"> ¿Por qué no puedo descargar la aplicación en mi dispositivo móvil? </span>
                        <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                      </button>
                    </div>
                    <div id="collapse-18" className="collapse" aria-labelledby="heading-18" data-parent="#frequent-questions">
                      <div className="card-body">
                        <span className="answer-question d-block"> Revisa el almacenamiento de tu dispositivo. Aunque la aplicación es ligera y solo requiere poco espacio, debes revisar que haya almacenamiento disponible. </span>
                        <span className="answer-question d-block"> Recuerda que la aplicaición está disponible para sistemas operativos: </span>
                        <span className="answer-question d-block"> Android: <a href="https://play.google.com/store/apps/details?id=io.uhoo.vanti"> play.google.com/store/apps/details?id=io.uhoo.vanti </a> </span>
                        <span className="answer-question d-block"> IOS: <a href="https://apps.apple.com/us/app/somos-vanti/id1506424507"> apps.apple.com/us/app/somos-vanti/id1506424507 </a>  </span>
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  <div className="card">
                    <div className="card-header m-0 p-0" id="heading-19">
                      <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-19" aria-expanded="false" aria-controls="collapse-19">
                        <span className="text-left question-tittle"> ¿Puedo chatear con mis compañeros de trabajo? </span>
                        <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                      </button>
                    </div>
                    <div id="collapse-19" className="collapse" aria-labelledby="heading-19" data-parent="#frequent-questions">
                      <div className="card-body answer-question">
                        Esta versión de la aplicación no tiene incluida una opción de chat. En versiones posteriores es posible su inclusión. De ser así, los usuarios registrados serán notificados en su debido momento.
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  <div className="card">
                    <div className="card-header m-0 p-0" id="heading-20">
                      <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-20" aria-expanded="false" aria-controls="collapse-20">
                        <span className="text-left  question-tittle"> ¿Puedo descargar una versión para mi ordenador? </span>
                        <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                      </button>
                    </div>
                    <div id="collapse-20" className="collapse" aria-labelledby="heading-20" data-parent="#frequent-questions">
                      <div className="card-body">
                        <p className="answer-question"> Esta plataforma está disponible únicamente para dispositivos móviles. Solo la plataforma administrativa tiene acceso desde ordenadores, para acceder a esta debes tener asignado un rol de administrador. </p>
                        <span className="answer-question d-block"> Recuerda que la app está disponible para sistemas operativos: </span>
                        <span className="answer-question d-block"> Android: <a href="https://play.google.com/store/apps/details?id=io.uhoo.vanti"> play.google.com/store/apps/details?id=io.uhoo.vanti </a> </span>
                        <span className="answer-question d-block"> IOS: <a href="https://apps.apple.com/us/app/somos-vanti/id1506424507"> apps.apple.com/us/app/somos-vanti/id1506424507 </a>  </span>
                      </div>
                    </div>
                  </div>
                  
                  
                  <div className="card">
                    <div className="card-header m-0 p-0" id="heading-21">
                      <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-21" aria-expanded="false" aria-controls="collapse-21">
                        <span className="text-left  question-tittle"> ¿Cómo cierro sesión? </span>
                        <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                      </button>
                    </div>
                    <div id="collapse-21" className="collapse" aria-labelledby="heading-21" data-parent="#frequent-questions">
                      <div className="card-body answer-question">
                        Para cerrar sesión, ve a la información de tu perfil, selecciona la opción ‘Editar’, elige ‘Cerrar sesión’ y confirma.
                      </div>
                    </div>
                  </div>
                  
                  
                  <div className="card">
                    <div className="card-header m-0 p-0" id="heading-22">
                      <button className="btn w-100 h-100 d-flex justify-content-between py-2 px-4 collapsed" type="button" data-toggle="collapse" data-target="#collapse-22" aria-expanded="false" aria-controls="collapse-22">
                        <span className="text-left  question-tittle"> ¿Cómo contacto a servicio de soporte? </span>
                        <img className="my-auto" style={{width: "20px"}} src="https://brilliantmedia.blob.core.windows.net/asset-bd3e8f99-ff36-413d-aed2-d9c295378cca/Desplegable-respuestas.png?sv=2017-04-17&sr=c&si=e883d135-1e97-481c-89c4-36bd51ca512f&sig=liKfVgBD9GVjIWHbthURxSO6HPjEuT7qaV1jmcaPFKA%3D&st=2020-04-20T21%3A42%3A55Z&se=2120-04-20T21%3A42%3A55Z" />
                      </button>
                    </div>
                    <div id="collapse-22" className="collapse" aria-labelledby="heading-21" data-parent="#frequent-questions">
                      <div className="card-body">
                        
                        <p className="answer-question"> Puedes encontrar el material instructivo y tutoriales en la sección de ayuda aquí: <a href="https://uhoo.io/help/"> uhoo.io/help </a>. </p>
                        <p className="answer-question"> Si eres administrador, los tutoriales del Hub están en este enlace: <a href="https://uhoo.io/video-ayuda-hub/"> uhoo.io/video-ayuda-hub </a>  </p> 
                        <p className="answer-question"> Si tienes una duda o requerimiento técnico, comunícate con el administrador asignado en tu comunidad al interior de tu organización. </p>
                        
                        
                      </div>
                    </div>
                  </div>
                  
                  
                  
                </div>
              </td>
            </tr>
  
          </tbody>
        </table>
        
        <div style={{maxWidth: "100vw"}}>
          <b style={{margin: "40px 0px", display: "block", textAlign: "center"}}>
            Si necesitas otro tipo de ayuda haz 
            <a style={{color: "#3b5eff"}} target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScVlH_S_eYBKLgZfQe011MbKL8AqMBRVkBMOnOv2of1H8k8tw/viewform" rel="noreferrer">
              clic aquí
            </a>
          </b>
        </div>
        
        <div class="footer">
          <div class="text-footer-page">
            © Desarrollado por Brilliant Code 2021
          </div>
        </div>
        
        
        <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
        
        </div>
      );
    }