import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    background: {
      default: "#E5E5E5"
    },
    primary: {
      main: '#183E55',
      dark: '#748996'
    },
    secondary: {
      main: '#FF8614',
    },
  },
  typography: {
    fontFamily: "'Mulish', sans-serif",
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
  },
});  