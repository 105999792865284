import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Stack,
    Typography,
} from '@mui/material';
import { UhooMarca } from '../../svgs/Uhoo-marca';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
import i18n from './localization/languaje';
import { t } from 'i18next';
import { useWidth } from '../../utils/useWidth';

interface IHeaderProps {
    onClickCompany: () => void;
    onClickFeatures: () => void;
    onClickPlans: () => void;
    onClickResources: () => void;
    onClickCustomers: () => void;
    openFormModal: () => void;
}

export const Header = (props: IHeaderProps) => {
    const [state, setState] = useState({
        mobileView: false,
    });

    const { mobileView } = state;

    const changeToEnglish = () => {
        i18n.changeLanguage('en');
    };

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({
                      ...prevState,
                      mobileView: false,
                  }));
        };

        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness());

        return () => {
            window.removeEventListener('resize', () => setResponsiveness());
        };
    }, []);

    const RenderDesktop = () => {
        return (
            <Stack
                direction='row'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}
                spacing={5}
            >
                <UhooMarca sx={{ width: 200, height: 100 }} />

                <Stack direction='row' spacing={8}>
                    <Button onClick={props.onClickCompany}>
                        <Typography color='primary' variant='subtitle1'>
                            {t('landing.header.button1')}
                        </Typography>
                    </Button>
                    <Button onClick={props.onClickFeatures}>
                        <Typography color='primary' variant='subtitle1'>
                            {t('landing.header.button2')}
                        </Typography>
                    </Button>
                    <Button onClick={props.onClickResources}>
                        <Typography color='primary' variant='subtitle1'>
                            {t('landing.header.button3')}
                        </Typography>
                    </Button>
                    <Button onClick={props.onClickCustomers}>
                        <Typography color='primary' variant='subtitle1'>
                            {t('landing.header.button4')}
                        </Typography>
                    </Button>
                </Stack>

                <Stack direction='row' spacing={4}>
                    <Button
                        sx={{ width: 128, height: 48 }}
                        href='https://hub.uhoo.io/#/login'
                        target='_blank'
                    >
                        {t('landing.header.button5')}
                    </Button>
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{ width: 128, height: 48 }}
                        onClick={props.openFormModal}
                    >
                        {t('landing.header.button6')}
                    </Button>
                </Stack>
            </Stack>
        );
    };

    const RenderMobile = () => {
        const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(
            null
        );

        const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElNav(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            setAnchorElNav(null);
        };
        return (
            <>
                <Stack
                    direction='row'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <UhooMarca sx={{ width: 200, height: 100, ml: -4 }} />
                    <IconButton onClick={handleOpenNavMenu}>
                        <MenuIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>
                </Stack>
                <Menu
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    keepMounted
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleCloseNavMenu();
                            props.onClickCompany();
                        }}
                    >
                        <Typography textAlign='center'>
                            {t('landing.header.button1')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleCloseNavMenu();
                            props.onClickFeatures();
                        }}
                    >
                        <Typography textAlign='center'>
                            {t('landing.header.button2')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleCloseNavMenu();
                            props.onClickResources();
                        }}
                    >
                        <Typography textAlign='center'>
                            {t('landing.header.button3')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleCloseNavMenu();
                            props.onClickCustomers();
                        }}
                    >
                        <Typography textAlign='center'>
                            {t('landing.header.button4')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleCloseNavMenu();
                        }}
                    >
                        <Button
                            href='https://hub.uhoo.io/#/login'
                            target='_blank'
                        >
                            {t('landing.header.button5')}
                        </Button>
                    </MenuItem>

                    <MenuItem>
                        <Button
                            variant='outlined'
                            color='secondary'
                            sx={{ width: 128, height: 48 }}
                            onClick={props.openFormModal}
                        >
                            {t('landing.header.button6')}
                        </Button>
                    </MenuItem>
                </Menu>
            </>
        );
    };

    return <>{mobileView ? <RenderMobile /> : <RenderDesktop />} </>;
};
