import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IComponentsProps } from '../../utils/types.d';
import { useWidth } from '../../utils/useWidth';

export const CompanyInfo = (props: IComponentsProps) => {
    const width = useWidth();
    const { t, i18n } = useTranslation();

    return (
        <Grid container display={'flex'} justifyContent={'center'} pt={12}>
            <Grid
                item
                xl={3.8}
                lg={5.1}
                md={6}
                xs={10}
                direction={'column'}
                display={'flex'}
                justifyContent={'center'}
                mr={width === 'xl' || width === 'lg' ? 10 : 0}
            >
                <Typography
                    color='primary'
                    variant={width === 'xs' ? 'h5' : 'h3'}
                    fontWeight='bold'
                >
                    {t('landing.company-info.title')}
                </Typography>
                <Typography
                    color='primary'
                    variant={width === 'xs' ? 'subtitle1' : 'h6'}
                    pt={4}
                    pr={width === 'xs' ? 0 : 11}
                >
                    {t('landing.company-info.description1')}
                </Typography>
                <Button
                    variant='contained'
                    color='secondary'
                    sx={{ width: { xs: '100%', lg: '23%' }, mt: 3 }}
                    onClick={props.openFormModal}
                >
                    <Typography color='white'>
                        {t('landing.company-info.button')}
                    </Typography>
                </Button>
            </Grid>

            <Grid
                item
                xl={3}
                md={4}
                display={'flex'}
                justifyContent={'center'}
                pt={5}
            >
                <img
                    src={require('../../assets/imgs/platform.jfif')}
                    alt={'item.title'}
                    loading='lazy'
                    width={width === 'xs' ? 300 : 571}
                    height={width === 'xs' ? 200 : 314}
                    style={{
                        objectFit: 'contain',
                    }}
                />
            </Grid>
        </Grid>
    );
};
