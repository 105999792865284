import { createSvgIcon } from '@mui/material/utils';

export const FacebookIcon = createSvgIcon(
    <svg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M15.6298 24.4965V14.9345H18.8556L19.3351 11.1906H15.6298V8.80595C15.6298 7.72562 15.9308 6.98595 17.4813 6.98595H19.446V3.64812C18.49 3.54568 17.5292 3.49621 16.5678 3.49995C13.7165 3.49995 11.7588 5.24062 11.7588 8.43612V11.1836H8.55396V14.9275H11.7658V24.4965H15.6298Z'
            fill='#183E55'
        />
    </svg>,
    'facebook'
);
