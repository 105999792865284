import { createSvgIcon } from '@mui/material/utils';

export const Icon2 = createSvgIcon(
  <svg
    // width='63'
    // height='63'
    viewBox='0 0 63 63'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
        d='M31.5 63C48.897 63 63 48.897 63 31.5C63 14.103 48.897 0 31.5 0C14.103 0 0 14.103 0 31.5C0 48.897 14.103 63 31.5 63Z'
        fill='white'
    />
    <path
        d='M31.2392 42.977C28.5757 41.3319 26.0997 39.3919 23.8564 37.1929C22.2729 35.6228 21.0634 33.7056 20.3206 31.5879C18.9934 27.4577 20.5384 22.7378 24.852 21.3365C27.1287 20.6214 29.6048 21.0604 31.5088 22.5164C33.4135 21.0621 35.8889 20.6232 38.1657 21.3365C42.4792 22.7378 44.0346 27.4577 42.7074 31.5879C41.9707 33.7036 40.7684 35.6206 39.1923 37.1929C36.947 39.3896 34.4714 41.3293 31.8096 42.977L31.5192 43.1667L31.2392 42.977Z'
        fill='#183E55'
    />
    <path
        d='M31.5134 43.1662L31.2393 42.9765C28.5724 41.3315 26.093 39.3917 23.8462 37.1924C22.2551 35.6244 21.0385 33.7071 20.2896 31.5874C18.9728 27.4573 20.5177 22.7373 24.8313 21.336C27.108 20.621 29.6168 21.0602 31.5134 22.5286V43.1662Z'
        fill='#FF8614'
    />
    <path
        d='M38.7682 29.1657C38.5339 29.1507 38.3156 29.0335 38.1647 28.8419C38.0136 28.6501 37.9431 28.4006 37.9696 28.1519C37.995 27.3324 37.5287 26.5866 36.8097 26.2964C36.3537 26.1719 36.0777 25.6782 36.1917 25.1912C36.3 24.7121 36.7485 24.4142 37.1995 24.522C37.2564 24.5315 37.3112 24.5521 37.3612 24.5828C38.8028 25.1377 39.7361 26.6308 39.6619 28.2634C39.6595 28.5109 39.563 28.7467 39.3946 28.9168C39.2261 29.0869 39 29.1768 38.7682 29.1657Z'
        fill='white'
    />
  </svg>,
  'Icon2'
);
