import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Stack,
    Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/system';
import { useWidth } from '../../utils/useWidth';
import { useTranslation } from 'react-i18next';
import { IComponentsProps } from '../../utils/types.d';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReactPlayer from 'react-player';
import { useState } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const PromptInfo = styled(Stack)<{ top?: string; left?: string }>(
    ({ top, left }) => ({
        backgroundColor: 'white',
        position: 'absolute',
        top: top,
        left: left,
        borderRadius: '10px',
    })
);

export const Main = (props: IComponentsProps) => {
    const width = useWidth();
    const { t, i18n } = useTranslation();

    const [openVideo, setOpenVideo] = useState(false);

    const handleOpen = () => setOpenVideo(true);
    const handleClose = () => setOpenVideo(false);

    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
        >
            <Grid
                item
                container
                py={width === 'xl' ? 20 : width === 'xs' ? 10 : 25}
                md={8}
                lg={7}
                xl={5}
                xs={11}
                direction='column'
                justifyContent={'center'}
                alignItems={'center'}
                textAlign={'center'}
            >
                <Grid item>
                    <Typography
                        variant='h2'
                        color='primary'
                        fontWeight='bold'
                        sx={{ fontSize: width !== 'xs' ? 64 : 28 }}
                    >
                        {t('landing.main.title')}
                    </Typography>
                </Grid>
                <Grid item px={width !== 'xs' ? 20 : 0} mt={7}>
                    <Typography
                        variant={width === 'xs' ? 'subtitle1' : 'h6'}
                        color='primary'
                    >
                        {t('landing.main.description')}
                    </Typography>
                </Grid>
                <Stack
                    direction={width === 'xs' ? 'column' : 'row'}
                    spacing={2}
                    pt={8}
                >
                    <Button
                        variant='outlined'
                        // color='secondary'
                        onClick={props.openFormModal}
                        sx={{ px: 2, py: 1 }}
                    >
                        {t('landing.main.button1')}
                    </Button>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={props.openFormModal}
                        endIcon={
                            <ArrowForwardIosIcon
                                fontSize='small'
                                sx={{ color: 'white' }}
                            />
                        }
                    >
                        <Typography color='white'>
                            {t('landing.main.button2')}
                        </Typography>
                    </Button>
                </Stack>
            </Grid>
            <Grid item xl={12} xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        backgroundImage: `url(${'assets/division-gray.svg'})`,
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        pb={10}
                        pt={20}
                        sx={{
                            display: 'flex',
                            alignItems: 'center !important',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            onClick={handleOpen}
                            sx={{
                                position: 'absolute',
                                color: '#FF8614',
                            }}
                        >
                            <PlayCircleIcon
                                sx={{
                                    fontSize: '100px',
                                }}
                            />
                        </IconButton>
                        <img
                            src={require('../../assets/imgs/main.jpg')}
                            alt={'item.title'}
                            loading='lazy'
                            width={width === 'xs' ? 225 : 425}
                            height={width === 'xs' ? 234 : 434}
                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                        />
                    </Box>

                    {width !== 'xs' && (
                        <>
                            <PromptInfo
                                direction='row'
                                spacing={2}
                                top='20%'
                                left='18%'
                                py={1}
                                px={3}
                            >
                                <CheckCircleOutlineIcon />
                                <Typography color='primary' variant='subtitle1'>
                                    {t('landing.main.prompt1')}
                                </Typography>
                            </PromptInfo>
                            <PromptInfo
                                direction='row'
                                spacing={2}
                                top='30%'
                                left='55%'
                                py={1}
                                px={3}
                            >
                                <CheckCircleOutlineIcon />
                                <Typography color='primary' variant='subtitle1'>
                                    {t('landing.main.prompt2')}
                                </Typography>
                            </PromptInfo>
                            <PromptInfo
                                direction='row'
                                spacing={2}
                                top='80%'
                                left='60%'
                                py={1}
                                px={3}
                            >
                                <CheckCircleOutlineIcon />
                                <Typography color='primary' variant='subtitle1'>
                                    {t('landing.main.prompt3')}
                                </Typography>
                            </PromptInfo>
                        </>
                    )}
                </Box>
            </Grid>
            <Modal open={openVideo} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xl: '53%', lg: '80%', xs: '100%' },
                        height: width !== 'xs' ? '60%' : '30%',
                        // height: '60%',
                        boxShadow: 24,
                        borderRadius: 2,
                        py: 1,
                    }}
                >
                    <ReactPlayer
                        url='videos/Video-uhoo.mp4'
                        width={'100%'}
                        height={'100%'}
                        playing={true}
                        loop
                        controls
                        // style={{
                        //     paddingTop: 200,
                        //     paddingBottom: 100,
                        // }}
                    />
                </Box>
            </Modal>
        </Grid>
    );
};
