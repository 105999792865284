import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon3 } from '../../svgs/Icon 3';
import { Icon1 } from '../../svgs/Icon1';
import { Icon2 } from '../../svgs/Icon2';
import { IComponentsProps } from '../../utils/types.d';
import { useWidth } from '../../utils/useWidth';

export const Goals = (props: IComponentsProps) => {
    const width = useWidth();
    const { t, i18n } = useTranslation();

    return (
        <>
            <Typography
                color='primary'
                variant={width === 'xs' ? 'h5' : 'h3'}
                pt={width === 'xs' ? 10 : 22}
                sx={{ textAlign: 'center' }}
                fontWeight='bold'
            >
                {t('landing.goals.goals-title')}
            </Typography>
            <Box display={'flex'} justifyContent={'center'}>
                <Grid
                    container
                    item
                    display={'flex'}
                    justifyContent={'center'}
                    pt={6}
                    xs={9.5}
                    md={12}
                >
                    <Grid item xl={1}></Grid>
                    <Grid
                        item
                        xs={8}
                        lg={3.5}
                        xl={2.5}
                        sx={
                            width === 'xs'
                                ? {
                                      display: 'contents',
                                      justifyContent: 'center',
                                      alignContent: 'center',
                                      textAlign: 'center',
                                      filter: 'none',
                                      boxShadow: 'none',
                                  }
                                : {
                                      width: 376,
                                      height: 184,
                                      filter: 'drop-shadow(0px 16px 50px rgba(112, 144, 176, 0.12))',
                                      boxShadow:
                                          '0px 16px 50px rgba(112, 144, 176, 0.12)',
                                      borderRadius: 4,
                                      px: 1,
                                      mb: 3,
                                  }
                        }
                    >
                        <Icon1 sx={{ width: 60, height: 60 }} />
                        <Typography
                            color='primary'
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            pr={4}
                            pl={2}
                        >
                            {t('landing.goals.goal1')}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        lg={3.5}
                        mx={2}
                        xl={2.5}
                        sx={
                            width === 'xs'
                                ? {
                                      display: 'contents',
                                      justifyContent: 'center',
                                      alignContent: 'center',
                                      textAlign: 'center',
                                      filter: 'none',
                                      boxShadow: 'none',
                                  }
                                : {
                                      width: 376,
                                      height: 184,
                                      filter: 'drop-shadow(0px 16px 50px rgba(112, 144, 176, 0.12))',
                                      boxShadow:
                                          '0px 16px 50px rgba(112, 144, 176, 0.12)',
                                      borderRadius: 4,
                                      px: 1,
                                      mb: 3,
                                  }
                        }
                    >
                        <Icon2 sx={{ width: 60, height: 60 }} />

                        <Typography
                            color='primary'
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            pr={4}
                            pl={2}
                        >
                            {t('landing.goals.goal2')}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        lg={3.5}
                        xl={2.5}
                        sx={
                            width === 'xs'
                                ? {
                                      display: 'contents',
                                      justifyContent: 'center',
                                      alignContent: 'center',
                                      textAlign: 'center',
                                      filter: 'none',
                                      boxShadow: 'none',
                                  }
                                : {
                                      width: 376,
                                      height: 184,
                                      filter: 'drop-shadow(0px 16px 50px rgba(112, 144, 176, 0.12))',
                                      boxShadow:
                                          '0px 16px 50px rgba(112, 144, 176, 0.12)',
                                      borderRadius: 4,
                                      px: 1,
                                      mb: 3,
                                  }
                        }
                    >
                        <Icon3 sx={{ width: 60, height: 60 }} />

                        <Typography
                            color='primary'
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            pr={4}
                            pl={2}
                        >
                            {t('landing.goals.goal3')}
                        </Typography>
                    </Grid>
                    <Grid item xl={1}></Grid>
                    <Grid
                        item
                        lg={3.5}
                        xl={2.5}
                        sx={
                            width === 'xs'
                                ? {
                                      display: 'contents',
                                      justifyContent: 'center',
                                      alignContent: 'center',
                                      textAlign: 'center',
                                      filter: 'none',
                                      boxShadow: 'none',
                                  }
                                : {
                                      width: 376,
                                      height: 184,
                                      filter: 'drop-shadow(0px 16px 50px rgba(112, 144, 176, 0.12))',
                                      boxShadow:
                                          '0px 16px 50px rgba(112, 144, 176, 0.12)',
                                      borderRadius: 4,
                                      px: 1,
                                      mb: 3,
                                      // pt: 2,
                                  }
                        }
                    >
                        <Icon1 sx={{ width: 60, height: 60 }} />
                        <Typography
                            color='primary'
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            pr={4}
                            pl={2}
                        >
                            {t('landing.goals.goal4')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={3.5}
                        xl={2.5}
                        sx={
                            width === 'xs'
                                ? {
                                      display: 'contents',
                                      justifyContent: 'center',
                                      alignContent: 'center',
                                      textAlign: 'center',
                                      filter: 'none',
                                      boxShadow: 'none',
                                  }
                                : {
                                      width: 376,
                                      height: 184,
                                      filter: 'drop-shadow(0px 16px 50px rgba(112, 144, 176, 0.12))',
                                      boxShadow:
                                          '0px 16px 50px rgba(112, 144, 176, 0.12)',
                                      borderRadius: 4,
                                      px: 1,
                                      // mb: 4,
                                      mx: 2,
                                      // pt: 2,
                                      pb: 3,
                                  }
                        }
                    >
                        <Icon1 sx={{ width: 60, height: 60 }} />
                        <Typography
                            color='primary'
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            pr={4}
                            pl={width === 'xs' ? 3 : 2}
                            pb={2}
                        >
                            {t('landing.goals.goal5')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={3.5}
                        xl={2.5}
                        sx={{
                            width: 376,
                            height: 184,
                            px: 1,
                            mb: 3,
                            pb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={props.openFormModal}
                        >
                            <Typography color='white'>
                                {t('landing.goals.button')}
                            </Typography>
                        </Button>
                    </Grid>

                    {/* <Grid
                    item
                    xl={2.5}
                    lg={3}
                    xs={10.5}
                    md={2.5}
                    pt={2}
                    direction={'column'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ textAlign: 'center' }}
                >
                    <Icon1 sx={{ width: 60, height: 60 }} />
                    <Typography color='primary' variant={width === 'xs' ? 'subtitle1' : 'h6'} pt={4}>
                        {t('landing.goals.goal1')}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xl={2.5}
                    lg={3}
                    md={2.5}
                    pt={2}
                    xs={10.5}
                    direction={'column'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ textAlign: 'center' }}
                    mx={width === 'xl' || width === 'lg' ? 11.5 : 0}
                >
                    <Icon2 sx={{ width: 60, height: 60 }} />
                    <Typography color='primary' variant={width === 'xs' ? 'subtitle1' : 'h6'} pt={4}>
                        {t('landing.goals.goal2')}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xl={2.5.2}
                    lg={3}
                    md={2.5}
                    pt={2}
                    xs={10.5}
                    direction={'column'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ textAlign: 'center' }}
                >
                    <Icon3
                        sx={{
                            width: 60,
                            height: 60,
                        }}
                    />
                    <Typography color='primary' variant={width === 'xs' ? 'subtitle1' : 'h6'} pt={4}>
                        {t('landing.goals.goal3')}
                    </Typography>
                </Grid> */}
                </Grid>
            </Box>
        </>
    );
};
