import {
    Box,
    Button,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { useWidth } from '../../utils/useWidth';
import { useTranslation } from 'react-i18next';
import { IComponentsProps } from '../../utils/types.d';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Join = (props: IComponentsProps) => {
    const width = useWidth();

    const { t, i18n } = useTranslation();

    return (
        <Grid container justifyContent={'center'} pt={20}>
            <Grid item xl={4} lg={5} xs={10}>
                <Typography
                    variant={width === 'xs' ? 'h5' : 'h3'}
                    color='primary'
                    fontWeight='bold'
                >
                    {t('landing.join.title')}
                </Typography>
            </Grid>
            <Grid item xl={3.2} lg={4.4} xs={10} pb={20} mt={1}>
                <Typography
                    variant={width === 'xs' ? 'subtitle1' : 'h6'}
                    color='primary'
                >
                    {t('landing.join.description')}
                </Typography>

                <Stack mt={5} direction='row' spacing={2}>
                    <img
                        src={require('../../assets/imgs/clinic.jfif')}
                        alt={'item.title'}
                        loading='lazy'
                        width={47}
                        height={64}
                        style={{ objectFit: 'cover' }}
                    />
                    <Box>
                        <Typography
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            color='primary'
                            fontWeight='bold'
                        >
                            {t('landing.join.client-name')}
                        </Typography>
                        <Typography
                            variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            color='primary'
                        >
                            {t('landing.join.client-sector')}
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                    <IconButton>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton>
                        <ChevronRightIcon />
                    </IconButton>
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{
                            mt: 5,
                            width: 222,
                            height: 40,
                            display: width === 'xs' ? 'none' : 'block',
                        }}
                    >
                        <Typography color='white'>
                            {t('landing.join.button1')}
                        </Typography>
                    </Button>
                </Stack>
            </Grid>

            <Grid
                item
                container
                sx={{
                    display: 'flex',
                    backgroundImage: `url(${'assets/division-blue.svg'})`,
                    backgroundSize: 'cover',
                    mt: 2,
                    justifyContent: 'center',
                    position: 'relative',
                    height: 676,
                }}
            >
                <Grid item container xl={10} lg={10} md={10} pt={25} xs={11}>
                    <Grid item xl={5.5} lg={7.5} xs={11}>
                        <Typography
                            variant={width !== 'xs' ? 'h3' : 'h5'}
                            color='white'
                            fontWeight='bold'
                        >
                            {t('landing.join.title2')}
                        </Typography>
                        <Button
                            variant='contained'
                            color='secondary'
                            sx={{
                                color: 'white',
                                width: 179,
                                height: 54,
                                mt: 8,
                            }}
                            endIcon={
                                <ArrowForwardIosIcon
                                    fontSize='small'
                                    sx={{ color: 'white' }}
                                />
                            }
                            onClick={props.openFormModal}
                        >
                            <Typography
                                color='white'
                                variant={width === 'xs' ? 'subtitle1' : 'h6'}
                            >
                                {t('landing.join.button2')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <img
                    src={require('../../assets/imgs/people.png')}
                    alt={'item.title'}
                    loading='lazy'
                    width={742}
                    height={656}
                    style={{
                        objectFit: 'cover',
                        position: 'relative',
                        top: '-61%',
                        left: '20%',
                        display: width !== 'xs' ? 'flex' : 'none',
                    }}
                />
            </Grid>
        </Grid>
    );
};
