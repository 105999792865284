import {
    FormControl,
    Grid,
    IconButton,
    OutlinedInput,
    Stack,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { LinkedinIcon } from '../../svgs/linkedin';
import { FacebookIcon } from '../../svgs/facebook';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useWidth } from '../../utils/useWidth';

interface ISocialNetworksProps {
    styles?: SxProps<Theme>;
}

const SocialNetworks = ({ styles }: ISocialNetworksProps) => {
    const width = useWidth();
    return (
        <Grid
            container
            display='flex'
            justifyContent={width === 'xs' ? 'space-around' : 'start'}
            alignItems={width === 'xs' ? 'center' : 'end'}
            rowSpacing={5}
            columnSpacing={5}
            pl={width === 'xs' ? 0 : 20}
        >
            <Grid item xs={10} lg={3}>
                <Typography variant='h6' color='primary' pb={1}>
                    Subscribe to our newsletter
                </Typography>
                <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <OutlinedInput
                        id='outlined-adornment-weight'
                        endAdornment={
                            <ArrowForwardIcon sx={{ color: '#00A4BD' }} />
                        }
                        aria-describedby='outlined-weight-helper-text'
                        sx={{ height: 40 }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={8} lg={2}>
                <Stack
                    direction='row'
                    display='flex'
                    justifyContent='space-around'
                    alignItems='center'
                >
                    <IconButton
                        href='https://www.instagram.com/connectwithuhoo/'
                        target='_blank'
                    >
                        <InstagramIcon
                            fontSize='large'
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                    <IconButton
                        href='https://www.linkedin.com/company/uhoo-dxp'
                        target='_blank'
                    >
                        <LinkedinIcon
                            fontSize='large'
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                    <IconButton
                        href='https://www.youtube.com/channel/UCYZnpykzbIMw0gMmqQwniQA'
                        target='_blank'
                    >
                        <YouTubeIcon
                            fontSize='large'
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                    <IconButton
                        href='https://www.facebook.com/connectwithuhoo'
                        target='_blank'
                    >
                        <FacebookIcon
                            fontSize='large'
                            sx={{ color: 'primary.main' }}
                        />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default SocialNetworks;
