import { createSvgIcon } from '@mui/material/utils';

export const Icon3 = createSvgIcon(
  <svg
    // width='67'
    // height='67'
    viewBox='0 0 67 67'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
        d='M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67Z'
        fill='white'
    />
    <path
        d='M31.2093 25.5043C31.2724 25.633 31.3141 25.7707 31.3328 25.9124L31.6783 31.0489L31.8497 33.6307C31.8516 33.8962 31.8931 34.16 31.9734 34.4136C32.1805 34.9055 32.6788 35.2182 33.2209 35.1963L41.4807 34.6561C41.8384 34.6502 42.1839 34.7839 42.4409 35.028C42.6552 35.2313 42.7936 35.4974 42.8372 35.7835L42.8519 35.9572C42.51 40.6902 39.0339 44.638 34.3106 45.657C29.5874 46.6761 24.7439 44.5234 22.4098 40.3676C21.7369 39.1603 21.3167 37.8333 21.1736 36.4644C21.1138 36.0592 21.0875 35.6498 21.095 35.2405C21.0875 30.166 24.7012 25.7791 29.7594 24.7215C30.3683 24.6267 30.9651 24.9489 31.2093 25.5043Z'
        fill='#183E55'
    />
    <path
        d='M34.579 21.0948C40.2365 21.2387 44.9916 25.3071 45.9068 30.7868L45.8981 30.8273L45.8732 30.8861L45.8767 31.0475C45.8636 31.2613 45.7811 31.467 45.6388 31.6332C45.4907 31.8063 45.2882 31.9242 45.0651 31.97L44.9291 31.9886L35.3993 32.6061C35.0823 32.6374 34.7667 32.5351 34.531 32.325C34.3345 32.1496 34.2089 31.9132 34.1734 31.6583L33.5338 22.1423C33.5226 22.1101 33.5226 22.0752 33.5338 22.0431C33.5425 21.7808 33.658 21.5327 33.8544 21.3546C34.0508 21.1764 34.3117 21.0829 34.579 21.0948Z'
        fill='#FF8614'
    />
  </svg>,
  'Icon 3'
);
