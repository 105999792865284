import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t, i18n } = useTranslation();

    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            pt={5}
        >
            <Grid item xl={5} lg={5} xs={12}>
                <Typography variant='body1' color='primary'>
                    {t('landing.footer.title')}
                </Typography>
            </Grid>
        </Grid>
    );
};
